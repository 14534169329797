import {
  LocationOnOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
} from "@material-ui/icons";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ui/Contact/ContactForm/ContactForm";
import ContactInfoItem from "../components/ui/Contact/ContactInfo/ContactInfoItem";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import { graphql } from "gatsby";
import { ContactInfo } from "../fragments/contactInfoFields";

interface ContactPageProps {
  data: {
    prismicContactInfo: ContactInfo;
  };
  location: Location;
}

const ContactPage: React.FC<ContactPageProps> = ({ data, location }) => {
  const contactInfo = data.prismicContactInfo.data;
  return (
    <Layout>
      <SEO
        title="Contact us"
        description="Work with the top custom home builder in Embro Ontario. We build stunning homes that last a lifetime and are built to stand up to the test of time."
        slug={location.pathname}
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white leading-none mb-0">Contact</h1>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-4/12 w-full mb-6 sm:mb-0">
              <h3 className="sm:text-3xl text-2xl">Contact Us</h3>
              <ul>
                <ContactInfoItem icon={LocationOnOutlined} title="Address">
                  <li>{contactInfo.address[0].street}</li>
                  <li>
                    <span className="mr-1">{contactInfo.address[0].city}</span>
                    <span>{contactInfo.address[0].zip_code}</span>
                  </li>
                  <li>{contactInfo.address[0].state}</li>
                </ContactInfoItem>
                <ContactInfoItem icon={EmailOutlined} title="Email">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  </li>
                </ContactInfoItem>
                <ContactInfoItem icon={PhoneAndroidOutlined} title="Phone">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`tel:${contactInfo.phone_number}`}
                    >
                      {formatPhoneNumber(contactInfo.phone_number)}
                    </a>
                  </li>
                </ContactInfoItem>
              </ul>
            </div>
            <div className="lg:w-5/12 sm:w-6/12 w-full">
              <h3 className="sm:text-3xl text-2xl">Send us a message</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0">
        <div className="container-wide">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.7387375309618!2d-80.98140188451639!3d43.21496887913869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ea4b07e0985cb%3A0xf5649705bf8a07b5!2s923652%20Rd%2092%2C%20Embro%2C%20ON%20N0J%201J0%2C%20Canada!5e0!3m2!1sen!2spl!4v1623577791537!5m2!1sen!2spl"
            loading="lazy"
            className="w-full h-[700px]"
          />
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    prismicContactInfo {
      ...contactInfoFields
    }
  }
`;
